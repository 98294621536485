
<template>
    <div class="account-page py-5 py-lg-6">
       <section class="">
            <div class="row">
                <div class="col-lg-12">
                    <div v-if="activeUser">
                    <div class="row">
                        <div class="col-lg-4 col-xl-4">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img :src="`${$store.state.domain}/${activeUser.image}`" class="rounded-circle user-photo img-thumbnail" alt="profile-image" />
                                    <h4 class="mt-3 mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h4>
                                    <p class="text-muted">{{activeUser.email}}</p>
                                </div>
                            </div>

                        </div>
                        <!-- end col-->
                        <div class="col-lg-8 col-xl-8">
                            <div class="card">
                                <div class="card-body">
                                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                                        <b-tab :active="activeTab === 'profile'">
                                            <template v-slot:title>
                                                <i class="mdi mdi-account-check mr-1"></i> Profile
                                            </template>
                                            <user-edit-tab-account />
                                        </b-tab>
                                        <b-tab :active="activeTab === 'settings'">
                                        <template v-slot:title>
                                            <i class="mdi mdi-account-cog mr-1"></i> Settings
                                        </template>
                                            <user-edit-tab-information />
                                        </b-tab>
                                        <b-tab :active="activeTab === 'password'">
                                        <template v-slot:title>
                                            <i class="mdi mdi-lock-check mr-1"></i> Password
                                        </template>
                                            <user-edit-tab-password />
                                        </b-tab>
                                    </b-tabs>
                                </div>
                                <!-- end card-box-->
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                    </div>
                </div>
            </div>
       </section>
    </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  name: "user-edit",
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
    activeTab(){
      return this.$route.query.tab ? this.$route.query.tab : 'profile';
    },
  },
  created() {
      this.$store.dispatch("fetchActiveUser");
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
